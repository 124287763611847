
.cta{
     display: inline-block;
     margin: 0 auto toRem(20) auto;
     padding: toRem(10) toRem(25);

     background: $trc-orange;
     color: $black;
     font-weight: bold;

     border-radius: toRem(5);

     text-decoration: none;
}