
.menu{
     display: none;

     @media (min-width: 48em) {
          
          display: block;

          ul{
               list-style-type: none;
               
               display: flex;

               li{
                    margin-right: toRem(5);

                    a{
                         padding: toRem(13) toRem(20);
                         border-radius: toRem(3);
                         color: $white;
                         text-decoration: none;

                         &:hover{
                              background: lighten($trc-grey, 10%);
                         }
                    }
               }
          }
     }
}