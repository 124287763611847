
.side-nav-overlay{
     width: 100%;
     height: 100vh;

     position: fixed;
     top: 0;
     left: 0;

     z-index: 99999999;
     overflow: hidden;
     
     .side-nav{
          width: 70%;
          height: 100%;
          padding-top: toRem(70);
          
          position: absolute;
          top: 0;
          right: 0;
          
          list-style-type: none;
          
          background: $trc-grey;

          li{
               width: 100%;
               margin-bottom: toRem(5);

               a{
                    display: inline-block;
                    box-sizing: border-box;
                    
                    width: 100%;
                    padding: toRem(15);
                    
                    color: $white;
                    font-weight: bold;
                    text-decoration: none;

                    border-left: 5px solid transparent;
                    
                    &:hover{
                         background: lighten($trc-grey, 10%);
                    }
                    &:active{
                         border-left: 5px solid $trc-orange;
                    }
               }
          }
     }

     &.hide-side-nav{
          visibility: hidden;
          background: transparent;
          transition: .5s ease;

          .side-nav{
               transform: translateX(100%);
               transition: .5s ease;
          }
     }
     &.show-side-nav{
          visibility: visible;
          background: rgba(0, 0, 0, 0.8);
          transition: .5s ease;

          .side-nav{
               transform: translateX(0%);
               transition: .5s ease;
          }
     }


     // For Tablet 
     @media (min-width: 26.5625em) {
          .side-nav{
               width: 60%;
          }
     }

     // Hide mobile side-nav on Tablet & Desktop
     @media (min-width: 48em) {
          display: none;
     }
}

