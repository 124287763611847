
.hero-section{
     width: 100%;
     background-size: cover;
     background-position-x: 50%;
     background-position-y: 70%;
     background-repeat: no-repeat;

     .hero-section-inner{
          width: 100%;
          background: rgba(0, 0, 0, 0.3);
          
          .hero-content{
               width: 90%;
               height: 80vh;
               
               margin: 0 auto;
               padding-top: toRem(70);
          }
     }
}