.grid-overlay{
     width: 80%;
     height: 100%;
     background: transparent;

     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     z-index: 100;
     
     display: grid;
     grid-template-columns: repeat(6, 1fr);
     column-gap: toRem(20);

     .col{
          background: rgba(0, 0, 0, 0.2);
     }

     @media (min-width: 1024px) {
          grid-template-columns: repeat(12, 1fr);
     }
}